<script>
import Vue from "vue"
import Gen from '../helper/Gen';

export default {
  props: {
    value:String,
    config:Object,
    defaultValue:{default:"#000001"}
  },
  computed:{
    optionsPlugin(){
      var options = {
        preferredFormat: "hex",
        showInput: true,
        showPaletteOnly: false,
        showAlpha: false,
        showPalette: false,
        showSelectionPalette: false,
        chooseText: "Choose",
        palette: this.palette,
        cancelText: "cancel",
        change: (color)=>{
          if(color===null) return this.$emit("input", null)
          this.$emit("input", color.toHexString())
        },
      }
      return Object.assign(options, this.config || {})
    }
  },
  data() {
    return {
      palette: [],
    }
  },
  mounted(){
    this.init()
  },
  methods:{
    async pluginInit(){
      Gen.loadCss("/plugins/spectrum/spectrum.css", 'top')
      await Gen.loadScript("/plugins/spectrum/spectrum.js")
    },
    async init(){
      await this.pluginInit()
      $(this.$el).spectrum(this.optionsPlugin).on('move.spectrum', (e)=>{
        var color = $(this.$el).spectrum("get")
        if(color===null) return this.$emit("input", null)
        this.$emit("input", color.toHexString())
      }).on('show.spectrum',(e)=>{
        setTimeout(()=>{ $(this.$el).spectrum("reflow") },1)
      });
      /* Using palette */
      if(this.optionsPlugin.showPalette){
        this.palette = []
        Gen.apirest("/select-colors", this.optionsPlugin.params,(err,resp)=>{
          if(err) return;
          $(this.$el).spectrum("destroy");
          this.palette = resp.filter(x=>{ return x.color }).map(x=>{ return x.color })
          // ((this.config||[]).palette).forEach(v=>{ this.palette.unshift(v) })
          $(this.$el).val(this.value).spectrum(this.optionsPlugin)
        })
      }
      if(!this.value) this.$emit("input", this.defaultValue)
    }
  },
  watch:{
    async value(v){
      await this.pluginInit()
      if(!v) $(this.$el).spectrum("set", this.defaultValue)
      if(v) $(this.$el).spectrum("set", v)
    },
    config(){
      Gen.delay(()=>{ this.init() }, 300)
    },
  },
  destroyed(){
    $(this.$el).spectrum("destroy")
  }
}
</script>

<style lang="scss">
.sp-replacer{
  display: block;
  background: #fff;
  padding: 6px;
  .sp-preview{
    width: calc(100% - 18px);
  }
}
</style>

<template>
  <input type="text">
</template>